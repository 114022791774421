// Third party libraries
import _ from 'lodash';

// Utils & Config
import AxiosService from '../utils/AxiosService';
import Config from '../config';

export interface CredentialsInterface {
    email: string;
    password: string;
    remember?: boolean;
};

export interface ProfileInterface {
    about?: string;
    city?: string;
    comercialName?: string;
    country?: string;
    email: string;
    gender?: number;
    name?: string;
    pictureUrl?: string;
    ssmm?: {
        web: string;
        facebook: string;
        instagram: string;
    };
    surname?: string;
    videoUrl?: string;
    _id: string;
};

export interface RecoveryInterface {
    email: string;
};

export interface SecurityInterface {
    currentPassword: string;
    newPassword: string;
};

const checkJwt = async () => _.get(await AxiosService.get(Config.server.api.securityApi + 'pro/check', true), 'data', {});
const changeEmail = async (payload: string) => await AxiosService.post(Config.server.api.securityApi + `changeemail`, payload, true);
const changePassword = async (payload: SecurityInterface) => await AxiosService.post(Config.server.api.securityApi + 'changepassword', payload, true);
const getProfile = async (): Promise<ProfileInterface> => _.get(await AxiosService.get(Config.server.api.securityApi + 'getprofile', true), 'data', {});
const login = async (payload: CredentialsInterface) => await AxiosService.post(Config.server.api.securityApi + 'pro/login', payload, false);
const recoveryPassword = async (payload: RecoveryInterface) => await AxiosService.post(Config.server.api.securityApi + `recoverypassword/${payload.email}`, {}, false);
const register = async (payload: CredentialsInterface) => await AxiosService.post(Config.server.api.securityApi + 'pro/register', payload, false);
const updateProfile = async (payload: ProfileInterface): Promise<{ item?: ProfileInterface }> => _.get(await AxiosService.post(Config.server.api.securityApi + 'saveprofile', payload, true), 'data', {});
const makingProfessionalPremiumRole = async () => await AxiosService.put(Config.server.api.securityApi + 'pro/make/premium', {}, true);

const exports = {
    checkJwt,
    changeEmail,
    changePassword,
    getProfile,
    login,
    recoveryPassword,
    register,
    updateProfile,
    makingProfessionalPremiumRole
}
export default exports;