
// Components
import { Image } from 'semantic-ui-react';
import GenericModal from '../genericModal';
import DemoImage from '../../assets/img/yogabot_icon.svg';

// Locales
import I18n from '../../../i18n';

// Styles
import './index.scss';

type ImageEnhancerProps = {
    open: boolean;
    title?: string;
    src?: string | null;
    onClose: () => void;
};

const ImageViewer = ({open, title, src, onClose}: ImageEnhancerProps) => {

  const renderBody = () => {
    return (
      <div className='image-enhancer'>
        <Image avatar={ false } className={ !!!src ? 'broken' : '' } src={ src || DemoImage } onError={ (e: any) => { e.target.classList.add('broken'); e.target.src = DemoImage } }  />
      </div>
    );
  };

  return (
    <GenericModal
      open={open}
      title={title || I18n.t('messages.enhanceImage')}
      size='small'
      renderBody={renderBody}
      onClose={onClose}
    />
  );
};

export default ImageViewer;
