// Third party libraries
import { Modal } from 'semantic-ui-react';

export type GenericModalProps = {
    open: boolean;
    renderActions?: () => JSX.Element;
    renderBody: () => JSX.Element;
    onClose?: () => void;
    title: string;
    size?: "mini" | "small" | "tiny" | "large" | "fullscreen" | undefined
};

export const ClosedModal: GenericModalProps = {
    open: false,
    renderActions: undefined,
    renderBody: () => <></>,
    title: '',
    size: "mini"
};

const GenericModal = ({ open, title, size = 'mini', renderBody, renderActions, onClose }: GenericModalProps) => {

    return (
        <Modal open={ open } size={size} closeIcon onClose={ onClose }>
            <Modal.Header>{ title }</Modal.Header>
            <Modal.Content scrolling>
                { renderBody() }
            </Modal.Content>
            { renderActions ? <Modal.Actions>{ renderActions() }</Modal.Actions> : '' }
        </Modal>
    );

}

export default GenericModal;