// Third party libraries
import { Icon } from 'semantic-ui-react';
import { useState } from 'react';

// Components
import CustomList, { ItemActionInterface, ItemInterface } from '../list';
import ImageViewer from '../imageViewer';

// Styled components
import OButton from '../../styled/button';

// Styles
import './index.scss';

// Locales
import I18n from '../../../i18n';

type Props = {
    title: string;
    items: ItemInterface[],
    itemFns?: ItemActionInterface[],
    itemComponents?: { render: (item: ItemInterface) => JSX.Element }[];
    onClickItemFn?: (item: ItemInterface) => void
    onAddNewItemFn?: () => void,
    onClickItemSpanFn?: (item: ItemInterface) => void,
    onAddNewItemLabel?: string;
};

const AssignedList = ({ title, items, itemFns, itemComponents, onClickItemFn = () => {}, onAddNewItemFn = () => {}, onClickItemSpanFn, onAddNewItemLabel }: Props) => {
    const [imageSrc, setImageSrc] = useState<string | null | undefined>(null);

    return (
        <div className='userAssignedList'>
            <span className='title'>{ title }</span>
            <div className='box'>
                <span className='title'>{ title }</span>
                <CustomList
                    items={ items }
                    onClickItemFn={ (item: ItemInterface) => onClickItemFn(item) }
                    itemFns={ itemFns }
                    itemComponents={itemComponents}
                    onClickItemSpanFn={ onClickItemSpanFn }
                />
                { !!!onAddNewItemLabel ? '' :
                    <div className='addButton'>
                        <OButton circular $primary icon $color={ 'var(--brand-secondary)' }><Icon name='plus' /></OButton>
                        <OButton className='textButton' $primary $color={ 'var(--brand-secondary)' } onClick={ onAddNewItemFn } ><span>{ onAddNewItemLabel }</span></OButton>
                    </div>
                }
            </div>
            <ImageViewer open={ imageSrc !== null } title={ I18n.t('groups.picture') } src={ imageSrc } onClose={ () => setImageSrc(null) } />
        </div>
    );

}

export default AssignedList;