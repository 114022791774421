// Third party libraries
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Divider, Icon } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';

// Redux
import { closeLoader, openLoader } from '../../../../../redux-store/loader';

// Models
import StudentModel, { StudentInterface, StudentSequenceInterface, USER_PRO_STATUS } from '../../../../../models/student';
import SequenceModel, { SequenceInterface } from '../../../../../models/sequence';

// Components
import AddStudent from './components/addStudent';
import BottomNav from '../../../../components/bottomNav/BottomNav';
import GenericModal, { GenericModalProps } from '../../../../components/genericModal';
import List, { ItemInterface } from '../../../../components/list';
import PanelNav from '../../../../components/nav/PanelNav';
import SecondarySubmenu, { BreadcrumbsInterface } from '../../../../components/nav/SecondarySubmenu';
import ImageViewer from '../../../../components/imageViewer';

// Styled components
import { Wrapper } from '../../../../styled/wrappers';
import OButton from '../../../../styled/button';

// Locales
import I18n from '../../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface MainDataInterface {
    sequence: SequenceInterface;
    breadcrumbs: BreadcrumbsInterface[];
    students: StudentInterface[];
};

const SequenceEditStudents = () => {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [mainData, setMainData] = useState<MainDataInterface>({
        sequence: {
            _id: '' + params.sequenceId,
            asanasCount: 0,
            createdAt: '',
            published: false,
            routine: [],
            targets: [],
            types: [],
            categories: [],
            description: { ES: '' },
            title: { ES: '' },
            level: ''
        },
        breadcrumbs: [],
        students: []
    });

    const [sequenceStudents, setSequenceStudents] = useState<StudentSequenceInterface[]>([]);
    const [studentList, setStudentList] = useState<ItemInterface[]>([]);

    const [modal, setModal] = useState<GenericModalProps>({ open: false, title: '', renderBody: () => <></>, renderActions: undefined });

    const [isPublishedSequence, setIsPublishedSequence] = useState(false);

    const [studentImageSrc, setStudentImageSrc] = useState<string | null | undefined>(null);

    const getSequenceStudents = async () => {

        try {

            dispatch(openLoader());

            const sequenceStudents = await SequenceModel.getSequenceStudents('' + params.sequenceId);
            setSequenceStudents(sequenceStudents);

            dispatch(closeLoader());

        } catch (e) {

            dispatch(closeLoader());
            setModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        }

    };

    useEffect(() => {

        const getData = async () => {

            try {

                dispatch(openLoader());

                const sequence = await SequenceModel.getSequence('' + params.sequenceId);

                const data = mainData;
                data.sequence = sequence;

                data.breadcrumbs = [{
                    key: 0,
                    label: I18n.t('menu.sequences'),
                    goTo: '/sequences'
                },
                {
                    key: 1,
                    label: sequence.title.ES,
                    goTo: '/sequences/edit/' + params.sequenceId
                },
                {
                    key: 2,
                    label: I18n.t('sequences.students'),
                    goTo: ''
                }];

                data.students = await StudentModel.getStudents();

                setMainData(data);

                setIsPublishedSequence(sequence.published);

                getSequenceStudents();

            } catch (e) {

                dispatch(closeLoader());
                setModal({
                    open: true,
                    title: I18n.t('buttons.actions.error'),
                    renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                    renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
                });

            }

        };

        getData();

    }, []);

    useEffect(() => {

        const students: ItemInterface[] = [];

        _.each(sequenceStudents, (sequenceStudent: any) => {

            const student = _.find(mainData.students, item => sequenceStudent.userId === item._id);

            students.push({
                _id: '' + student?._id,
                isAvatar: true,
                header: '' + student?.name,
                selectable: false,
                selected: false,
                draggable: false,
                description: '' + student?.surname,
                pictureUrl: student?.pictureUrl
            });

        });

        setStudentList(students);

    }, [sequenceStudents]);

    const goBack = () => navigate(`/sequences/edit/${ params.sequenceId }`);

    const closeModal = () => setModal({ open: false, title: '', renderBody: () => <></>, renderActions: undefined });

    const onAddStudent = () => {

        const unlinkedStudents: StudentInterface[] = [];

        _.each(mainData.students, student => {

            const exists = !!_.find(studentList, item => item._id === student._id);
            if (!exists && student.status === USER_PRO_STATUS.LINKED) {

                unlinkedStudents.push(student);

            }

        });

        setModal({
            open: true,
            title: I18n.t('sequences.addStudent'),
            renderBody: () => <AddStudent
                sequenceId={ '' + params.sequenceId }
                isPublishedSequence={ isPublishedSequence }
                onChangeModal={ (modal: GenericModalProps) => setModal(modal) }
                onForceUpdate={ getSequenceStudents }
                students={ unlinkedStudents }
            />
        });

    };

    const onRemoveStudent = async (item: ItemInterface) => {

        dispatch(openLoader());

        try {

            await StudentModel.unassignSequence(item._id, '' + params.sequenceId);
            getSequenceStudents();

        } catch (e) {

            dispatch(closeLoader());
            setModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        }

    };

    return (
        <Wrapper>
            <GenericModal
                open={ modal.open }
                title={ modal.title }
                renderBody={ modal.renderBody }
                renderActions={ modal.renderActions }
                onClose={ closeModal }
            />
            <PanelNav active='sequences' />
            <Container style={{ paddingTop: '20px' }}>
                <SecondarySubmenu
                    title={ I18n.t('sequences.students') }
                    mainFn={{ callback: goBack, icon: 'angle left', label: I18n.t('buttons.actions.goBack') }}
                    breadcrumbs={ mainData.breadcrumbs }
                />
                <div className='inner'>
                    <div className='p-flex'>
                        <div className='p-content'>
                            <div className='a-wrapper'>
                                <div className='a-f-wrapper'>
                                    <div className='studentList'>
                                        { _.size(studentList) === 0 &&
                                            <div className='studentsEmpty'>
                                                <Icon name='info circle' />
                                                <span>{ I18n.t('sequences.studentsEmpty') }</span>
                                            </div>
                                        }
                                        <List
                                            items={ studentList }
                                            onClickItemFn={ item => navigate(`/students/edit/${ item._id }`) }
                                            itemFns={[{ icon: 'trash alternate outline', callback: onRemoveStudent }]}
                                            onClickItemSpanFn={ (item: ItemInterface)=>  setStudentImageSrc(item.pictureUrl) }
                                        />
                                        <Divider />
                                        <div className='addStudent'>
                                            <OButton type='button' $color='var(--brand-secondary)' $primary onClick={ onAddStudent }>
                                                <span>{ I18n.t('sequences.addStudent') }</span>
                                            </OButton>
                                        </div>
                                        <Divider />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <BottomNav active='sequences' />
            <ImageViewer open={ studentImageSrc !== null } title={ I18n.t('students.picture') } src={ studentImageSrc } onClose={ () => setStudentImageSrc(null) } />
        </Wrapper>
    );

}

export default SequenceEditStudents;