// Third party libraries
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Divider, Icon } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';

// Redux
import { closeLoader, openLoader } from '../../../../../redux-store/loader';

// Models
import GroupModel, { GroupInterface, GroupSequenceInterface } from '../../../../../models/group';
import SequenceModel, { SequenceInterface } from '../../../../../models/sequence';

// Components
import AddGroup from './components/addGroup';
import BottomNav from '../../../../components/bottomNav/BottomNav';
import GenericModal, { GenericModalProps } from '../../../../components/genericModal';
import List, { ItemInterface } from '../../../../components/list';
import PanelNav from '../../../../components/nav/PanelNav';
import SecondarySubmenu, { BreadcrumbsInterface } from '../../../../components/nav/SecondarySubmenu';

// Styled components
import { Wrapper } from '../../../../styled/wrappers';
import OButton from '../../../../styled/button';

// Locales
import I18n from '../../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface MainDataInterface {
    sequence: SequenceInterface;
    breadcrumbs: BreadcrumbsInterface[];
    groups: GroupInterface[];
};

const SequenceEditGroups = () => {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [mainData, setMainData] = useState<MainDataInterface>({
        sequence: {
            _id: '' + params.sequenceId,
            asanasCount: 0,
            createdAt: '',
            published: false,
            routine: [],
            targets: [],
            types: [],
            categories: [],
            description: { ES: '' },
            title: { ES: '' },
            level: ''
        },
        breadcrumbs: [],
        groups: []
    });

    const [sequenceGroups, setSequenceGroups] = useState<GroupSequenceInterface[]>([]);
    const [groupList, setGroupList] = useState<ItemInterface[]>([]);

    const [modal, setModal] = useState<GenericModalProps>({ open: false, title: '', renderBody: () => <></>, renderActions: undefined });

    const [isPublishedSequence, setIsPublishedSequence] = useState(false);

    const getSequenceGroups = async () => {

        try {

            dispatch(openLoader());

            const sequenceGroups = await SequenceModel.getSequenceGroups('' + params.sequenceId);
            setSequenceGroups(sequenceGroups);

            dispatch(closeLoader());

        } catch (e) {

            dispatch(closeLoader());
            setModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        }

    };

    useEffect(() => {

        const getData = async () => {

            try {

                dispatch(openLoader());

                const sequence = await SequenceModel.getSequence('' + params.sequenceId);

                const data = mainData;
                data.sequence = sequence;

                data.breadcrumbs = [{
                    key: 0,
                    label: I18n.t('menu.sequences'),
                    goTo: '/sequences'
                },
                {
                    key: 1,
                    label: sequence.title.ES,
                    goTo: '/sequences/edit/' + params.sequenceId
                },
                {
                    key: 2,
                    label: I18n.t('sequences.groups'),
                    goTo: ''
                }];

                data.groups = await GroupModel.getGroups();

                setMainData(data);

                setIsPublishedSequence(sequence.published);

                getSequenceGroups();

            } catch (e) {

                dispatch(closeLoader());
                setModal({
                    open: true,
                    title: I18n.t('buttons.actions.error'),
                    renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                    renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
                });

            }

        };

        getData();

    }, []);

    useEffect(() => {

        const groups: ItemInterface[] = [];

        _.each(sequenceGroups, (sequenceGroup: any) => {

            const group = _.find(mainData.groups, item => sequenceGroup.groupId === item._id);

            groups.push({
                _id: '' + group?._id,
                isAvatar: false,
                header: '' + group?.name,
                selectable: false,
                selected: false,
                draggable: false,
                pictureUrl: group?.imageUrl
            });

        });

        setGroupList(groups);

    }, [sequenceGroups]);

    const goBack = () => navigate(`/sequences/edit/${ params.sequenceId }`);

    const closeModal = () => setModal({ open: false, title: '', renderBody: () => <></>, renderActions: undefined });

    const onAddGroup = () => {

        const unlinkedGroups: GroupInterface[] = [];

        _.each(mainData.groups, group => {

            if (!!!_.find(groupList, item => item._id === group._id)) {

                unlinkedGroups.push(group);

            }

        });

        setModal({
            open: true,
            title: I18n.t('sequences.addGroup'),
            renderBody: () => <AddGroup
                sequenceId={ '' + params.sequenceId }
                isPublishedSequence={ isPublishedSequence }
                onChangeModal={ (modal: GenericModalProps) => setModal(modal) }
                onForceUpdate={ getSequenceGroups }
                groups={ unlinkedGroups }
            />
        });

    };

    const onRemoveGroup = async (item: ItemInterface) => {

        dispatch(openLoader());

        try {

            await GroupModel.unassignSequence(item._id, '' + params.sequenceId);
            getSequenceGroups();

        } catch (e) {

            dispatch(closeLoader());
            setModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        }

    };

    return (
        <Wrapper>
            <GenericModal
                open={ modal.open }
                title={ modal.title }
                renderBody={ modal.renderBody }
                renderActions={ modal.renderActions }
                onClose={ closeModal }
            />
            <PanelNav active='sequences' />
            <Container style={{ paddingTop: '20px' }}>
                <SecondarySubmenu
                    title={ I18n.t('sequences.groups') }
                    mainFn={{ callback: goBack, icon: 'angle left', label: I18n.t('buttons.actions.goBack') }}
                    breadcrumbs={ mainData.breadcrumbs }
                />
                <div className='inner'>
                    <div className='p-flex'>
                        <div className='p-content'>
                            <div className='a-wrapper'>
                                <div className='a-f-wrapper'>
                                    <div className='groupList'>
                                        { _.size(groupList) === 0 &&
                                            <div className='groupsEmpty'>
                                                <Icon name='info circle' />
                                                <span>{ I18n.t('sequences.groupsEmpty') }</span>
                                            </div>
                                        }
                                        <List
                                            items={ groupList }
                                            onClickItemFn={ item => navigate(`/groups/edit/${ item._id }`) }
                                            itemFns={[{ icon: 'trash alternate outline', callback: onRemoveGroup }]}
                                        />
                                        <Divider />
                                        <div className='addGroup'>
                                            <OButton type='button' $color='var(--brand-secondary)' $primary onClick={ onAddGroup }>
                                                <span>{ I18n.t('sequences.addGroup') }</span>
                                            </OButton>
                                        </div>
                                        <Divider />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <BottomNav active='sequences' />
        </Wrapper>
    );

}

export default SequenceEditGroups;