// Third party libraries
import _ from 'lodash';
import React from 'react';
import { Checkbox, Icon, Image, List, SemanticICONS } from 'semantic-ui-react';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';

// Locales
import I18n from '../../../i18n';

// Styles
import './index.scss';

// Assets
import DemoImage from '../../assets/img/yogabot_icon.svg';

export interface ItemInterface {
    _id: string;
    description?: string;
    draggable?: boolean;
    extraData?: any;
    header: string;
    isAvatar?: boolean;
    pictureUrl?: string;
    selectable?: boolean;
    selected?: boolean;
};

export interface ItemActionInterface {
    callback: (item: ItemInterface) => void;
    disableOnDesktop?: (item: ItemInterface) => boolean;
    disableOnMobile?: (item: ItemInterface) => boolean;
    icon: SemanticICONS;
};

type Props = {
    draggableList?: boolean;
    editMode?: boolean;
    items: ItemInterface[];
    itemComponents?: { render: (item: ItemInterface) => JSX.Element }[];
    itemFns?: ItemActionInterface[];
    onClickItemFn?: (item: ItemInterface) => void;
    onClickItemSpanFn?: (item: ItemInterface) => void;
    onDropItemFn?: (result: DropResult, provided: ResponderProvided) => void;
    onSelectItemFn?: (id: string, checked: boolean) => void;
};

const CustomList = ({ draggableList = false, editMode = false, items, itemComponents = [], itemFns = [], onClickItemFn = () => {}, onClickItemSpanFn, onDropItemFn = () => {}, onSelectItemFn = () => {} }: Props) => {

    const getInitials = (text: string) => (text.substring(0, 1) + (text.includes(' ') ? text.substring(text.indexOf(' ') + 1, text.indexOf(' ') + 2) : '')).toUpperCase();

    const mobileItemFnsCount = (item: ItemInterface) => _.size(_.filter(itemFns, action => typeof action.disableOnMobile === 'undefined' || !action.disableOnMobile(item)));
    const desktopItemFnsCount = (item: ItemInterface) => _.size(_.filter(itemFns, action => typeof action.disableOnDesktop === 'undefined' || !action.disableOnDesktop(item)));

    const renderItem = (item: ItemInterface) => (
        <List.Item key={ item._id } className={ `selectableListItem ${ editMode ? 'editMode' : '' } ${ item.selected ? 'selected' : '' } ${ item.draggable ? 'draggable' : '' }` }>
            { item.selectable && <Checkbox checked={ item.selected } onChange={ (e, data) => onSelectItemFn(item._id, !!data.checked) } /> }
            { item.draggable && <Icon name='move' className='moveIcon' /> }
            <span className={ `initials ${ !!!item.pictureUrl ? 'visible' : ''} ${ item.isAvatar ? 'avatar': '' }` } onClick={ onClickItemSpanFn !== undefined ? () => onClickItemSpanFn(item) : () => onClickItemFn(item) }>{ getInitials(!!item.header && !!item.description ? item.header + ' ' + item.description : I18n.t('students.noName')) }</span>
            <Image avatar={ !!item.isAvatar } className={ !!!item.pictureUrl ? 'broken' : '' } src={ item.pictureUrl || DemoImage } onError={ (e: any) => { e.target.classList.add('broken'); e.target.src = DemoImage } } onClick={ onClickItemSpanFn !== undefined ? () => onClickItemSpanFn(item) : () => onClickItemFn(item) } />
            <List.Content className={ `${ editMode ? 'editMode' : '' } ${ _.size(itemComponents) === 0 ? '' : 'withComponents' } ${ _.size(itemFns) === 0 ? '' : 'withActions desktopActions_' + desktopItemFnsCount(item) + ' mobileActions_' + mobileItemFnsCount(item) }` } onClick={ () => onClickItemFn(item) }>
                <List.Header>{ item.header }</List.Header>
                { item.description && <List.Description>{ item.description }</List.Description> }
            </List.Content>
            { _.size(itemComponents) === 0 ? '' :
                <div className='itemComponents'>
                    { itemComponents.map(component => <React.Fragment key={ item._id }>{ component.render(item) }</React.Fragment>) }
                </div>
            }
            { _.size(itemFns) === 0 ? '' :
                <div className={ 'itemFns desktopActions_' + desktopItemFnsCount(item) + ' mobileActions_' + mobileItemFnsCount(item) }>
                    { itemFns.map((action, index) => <Icon key={ index } name={ action.icon } onClick={ () => action.callback(item) } className={ `${ typeof action.disableOnMobile === 'undefined' || !action.disableOnMobile(item) ? '' : 'hideOnMobile' } ${ typeof action.disableOnDesktop === 'undefined' || !action.disableOnDesktop(item) ? '' : 'hideOnDesktop' }` } />) }
                </div>
            }
        </List.Item>
    );

    return draggableList ? (
        <DragDropContext onDragEnd={ onDropItemFn }>
            <Droppable droppableId='list-container'>
                { provided => (
                    <div className='list-container' { ...provided.droppableProps } ref={ provided.innerRef }>
                        <List selection>
                            { items.map((item, index) => (
                                <Draggable key={ item._id } draggableId={ item._id } index={ index }>
                                    { provided => (
                                        <div className='item-container' ref={ provided.innerRef } { ...provided.dragHandleProps } { ...provided.draggableProps }>
                                            { renderItem(item) }
                                        </div>
                                    ) }
                                </Draggable>
                            )) }
                            {provided.placeholder}
                        </List>
                    </div>
                ) }
            </Droppable>
        </DragDropContext>
    ) : (
        <List selection>
            { items.map(item => renderItem(item)) }
        </List>
    );

}

export default CustomList;