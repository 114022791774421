// Third party libraries
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Form } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../../redux-store/loader';

// Models
import GroupModel from '../../../../models/group';
import StudentModel, { StudentInterface } from '../../../../models/student';

// Components
import { FormSelectOptionsInterface } from '../../../components/form/select';
import { ClosedModal, GenericModalProps } from '../../../components/genericModal';

// Styled components
import OButton from '../../../styled/button';

// Locales
import I18n from '../../../../i18n';

type Props = {
    student: StudentInterface | StudentInterface[],
    onChangeModal: (modal: GenericModalProps) => void,
    onForceUpdate: () => void
};

const AddToGroup = ({ student, onChangeModal, onForceUpdate }: Props) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [groupId, setGroupId] = useState<string>('');
    const [groupToAssignSelectOptions, setGroupToAssignSelectOptions] = useState<FormSelectOptionsInterface[]>([]);

    useEffect(() => {

        const getData = async () => {

            const groups = await GroupModel.getGroups();
            const groupToAssignSelectOptions: FormSelectOptionsInterface[] = [];
            _.each(groups, group => {

                if (_.isArray(student) || !student.groups.includes(group._id)) {

                    groupToAssignSelectOptions.push({
                        key: group._id,
                        text: group.name,
                        value: group._id
                    });

                }

            });

            setGroupToAssignSelectOptions(groupToAssignSelectOptions);

            let groupId: string | number = '';
            if (_.size(groupToAssignSelectOptions)) {

                groupId = groupToAssignSelectOptions[0].key;

            }

            setGroupId('' + groupId);

        };

        getData();

    }, [student]);

    const handleAddGroupSubmit = async () => {

        try {

            closeModal();
            dispatch(openLoader());

            let students: StudentInterface[] = [];
            if (!_.isArray(student)) {

                students.push(student);

            } else {

                students = [ ...student ];

            }

            for (let i = 0; i < _.size(students); i++) {

                await StudentModel.assignToGroup('' + students[i]._id, '' + groupId);

            }

            setGroupId('');
            onForceUpdate();

        } catch (e) {

            dispatch(closeLoader());
            onChangeModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        }

    }

    const closeModal = () => onChangeModal(ClosedModal);

    return (
        <Form onSubmit={ handleAddGroupSubmit }>
            <Form.Field>
                <span style={{ paddingBottom: '10px', display: 'block' }}>
                {
                        _.isEmpty(groupToAssignSelectOptions) ?
                            <strong>{ I18n.t('students.emptyGroupList') }</strong>
                            :
                            <strong>{ I18n.t('students.chooseGroup') }</strong>
                    }
                </span>
                { _.isEmpty(groupId) ?
                    <span style={{ paddingBottom: '10px', display: 'block', color: 'red' }}>
                        <OButton className='textButton' $quarth onClick={ ()=> navigate('/groups/new') } ><span>{ I18n.t('students.createGroup')} </span></OButton>
                    </span>
                    :
                    <Dropdown
                        search={ true }
                        options={ groupToAssignSelectOptions }
                        value={ groupId }
                        selection
                        fluid
                        onChange={ (e, { value }) => setGroupId('' + value) }
                        onBlur={ (e, { value }) => setGroupId('' + value) }
                        style={{ marginBottom: '10px' }}
                    />
                }
                <div>
                    <OButton fluid type='submit' $color='white' $terciary disabled={ _.isEmpty(groupId) }>
                        <span>{ I18n.t('students.addGroup') }</span>
                    </OButton>
                </div>
            </Form.Field>
        </Form>
    );

}

export default AddToGroup;