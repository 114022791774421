// Third party libraries
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Breadcrumb } from 'semantic-ui-react';

// Models
import SequenceModel, {
  SequenceFolderInterface,
} from '../../../../models/sequence';

// Locales
import I18n from '../../../../i18n';

// Interfaces
interface BreadcrumbInterface {
  _id: string;
  name: string;
}

interface FoldersBreadcrumbProps {
  folder?: SequenceFolderInterface;
}

const FoldersBreadcrumb = ({ folder }: FoldersBreadcrumbProps) => {
  const navigate = useNavigate();

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbInterface[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (folder) {
        setIsLoading(true);
        const breadcrumbs = await SequenceModel.getSequenceFolderPath(
          folder.parentId
        );

        setBreadcrumbs(breadcrumbs);
        setIsLoading(false);
      }
    };

    getData();
  }, [folder, folder?.parentId]);

  return (
    <Breadcrumb style={{ marginBottom: '1rem' }}>
      <Breadcrumb.Section link>
        <span onClick={() => navigate(`/sequences`)}>
          {I18n.t('menu.sequences')}
        </span>
      </Breadcrumb.Section>
      <Breadcrumb.Divider />
      {isLoading ? (
        <React.Fragment>
          <Breadcrumb.Section>
            <span>...</span>
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
        </React.Fragment>
      ) : (
        _.map(breadcrumbs, (breadcrumb, index) => {
          return (
            <React.Fragment key={index}>
              <Breadcrumb.Section link>
                {!_.isEmpty(breadcrumb) ? (
                  <span
                    onClick={() =>
                      navigate(`/sequences?folderId=${breadcrumb._id}`)
                    }
                  >
                    {breadcrumb.name}
                  </span>
                ) : (
                  breadcrumb.name
                )}
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
            </React.Fragment>
          );
        })
      )}
      {folder && (
        <Breadcrumb.Section link active>
          <span onClick={() => navigate(`/sequences?folderId=${folder._id}`)}>
            {folder.name}
          </span>
        </Breadcrumb.Section>
      )}
    </Breadcrumb>
  );
};

export default FoldersBreadcrumb;
