// Third party libraries
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CardNumberElement, CardExpiryElement, CardCvcElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Form } from 'semantic-ui-react';

// Styled components
import OButton from '../../styled/button';

// Models
import PaymentModel, { NewSubscriptionInterface } from '../../../models/payment';

// Redux
import { openLoader } from '../../../redux-store/loader';

// Locales
import I18n from '../../../i18n';

// Interfaces
interface PropsInterface {
    amount: number;
    currency: string;
    description: string;
    elements: any;
    onError: (error: any) => void;
    onSuccess: () => void;
    periodic: boolean;
    stripe: any;
    type: string;
};

const StripeCheckoutForm = ({ amount, currency, description, elements, onError, onSuccess, periodic, stripe, type }: PropsInterface) => {

    const dispatch = useDispatch();

    const [paymentRequest, setPaymentRequest] = useState(null);

    const [formProcessing, setFormProcessing] = useState<boolean>(false);

    useEffect(() => {
        console.log('Entra aqui...', stripe, amount, currency)
        if (stripe && amount && currency) {
          const pr = stripe.paymentRequest({
            country: 'US',
            currency: currency.toLowerCase(),
            total: {
              label: description || 'Demo total',
              amount: amount * 100,
            },
            requestPayerName: true,
            requestPayerEmail: true,
          });
    
          // Check the availability of the Payment Request API.
          pr.canMakePayment().then((result: any) => {
            if (result) {
              console.log('Entra aqui...', result, pr)
              setPaymentRequest(pr);
            }
          });
    
          // Manejar el evento 'paymentmethod'
          pr.on('paymentmethod', async (event: any) => {
            try {
              event.complete('success');
            } catch (error) {
              event.complete('fail');
              onError(error);
            }
          });
        }
      }, [
        amount,
        currency,
        description,
        onError,
        onSuccess,
        periodic,
        stripe,
        type,
      ]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        if (!formProcessing) {

            setFormProcessing(true);

            dispatch(openLoader());

            try {

                const { paymentMethod, error } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement('cardNumber'),
                });

                if (error) {

                    setFormProcessing(false);
                    throw error.message;

                }

                if (periodic) {

                    const subscriptionData: NewSubscriptionInterface = {
                        amount: amount * 100,
                        currency: currency,
                        paymentMethodId: paymentMethod.id,
                        platform: 'stripe',
                        type
                    };

                    const subscription = await PaymentModel.createSubscription(subscriptionData);

                    await PaymentModel.createPayment({
                        amount,
                        currency,
                        description,
                        type,
                        platform: 'stripe',
                        brand: paymentMethod.card.brand,
                        last4: paymentMethod.card.last4,
                        orderId: subscription.orderId
                    });

                    onSuccess();

                }

            } catch (error) {

                setFormProcessing(false);
                onError(error);

            }

        }

    };

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                fontSmoothing: 'antialiased',
                fontSize: '1em'
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        },
        hidePostalCode: true
    };

    return (
        <Form onSubmit={ handleSubmit }>
            {paymentRequest && (
                <PaymentRequestButtonElement options={{ paymentRequest }} />
            )}
            <Form.Field>
                <label>{ I18n.t('stripe.cardNumber') }</label>
                <CardNumberElement id='card_number' options={ CARD_ELEMENT_OPTIONS }/>
            </Form.Field>
            <Form.Group>
                <div className='cl-12'>
                    <Form.Field>
                        <label>{ I18n.t('stripe.expirationDate') }</label>
                        <CardExpiryElement id='card_expiry' options={ CARD_ELEMENT_OPTIONS }/>
                    </Form.Field>
                </div>
                <div className='cl-4'>
                    <Form.Field>
                        <label>CVC</label>
                        <CardCvcElement id='card_cvc' options={ CARD_ELEMENT_OPTIONS }/>
                    </Form.Field>
                </div>
            </Form.Group>
            { formProcessing ?
                <OButton type='button' disabled={ true } $terciary fluid $color='#fff' $upper>{ I18n.t('setup.processingPayment') }</OButton> :
                <OButton type='submit' disabled={ !stripe } $terciary fluid $color='#fff' $upper>{ I18n.t('setup.proccessPayment') }</OButton>
            }
        </Form>
    );

}

export default StripeCheckoutForm;