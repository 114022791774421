// Third party libraries
import _ from 'lodash';
import { useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useSearchParams } from 'react-router-dom';

// Models
import { NewSequenceFolderInterface, SequenceFolderInterface } from '../../../../models/sequence';

// Styled components
import OButton from '../../../styled/button';

// Locales
import I18n from '../../../../i18n';

// Fill CONST
type Props = {
    folder?: SequenceFolderInterface
    onSubmit: (newSequence: NewSequenceFolderInterface | SequenceFolderInterface) => void
};

const EditFolderModalBody = ({folder, onSubmit }: Props) => {

    const [name, setName] = useState<string>(folder?.name || '');
    
    const [searchParams] = useSearchParams();

    const handleSubmitEditSequence = () => {

        if (folder){
            const folderUpdated = _.cloneDeep(folder);
            folderUpdated.name = name;
            onSubmit(folderUpdated);
            return;
        }

        const newFolder: NewSequenceFolderInterface | SequenceFolderInterface = {
            name,
            parentId: searchParams.get('folderId') || 'none'
        }

        onSubmit(newFolder);

    }

    return (
        <Form onSubmit={ handleSubmitEditSequence }>
            <Form.Field>
                <Input
                    fluid
                    value={ name }
                    onChange={ (e, { value }) => setName(value) }
                    style={{ margin: '15px 0' }}
                />
                <div>
                    <OButton fluid type='submit' $color='white' $terciary disabled={ _.isEmpty(name) } >
                        {
                            folder ? I18n.t('buttons.actions.editFolder') : I18n.t('buttons.actions.createFolder')
                        }
                    </OButton>
                </div>
            </Form.Field>
        </Form>
    );

}

export default EditFolderModalBody;