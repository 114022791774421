// Third party libraries
import _ from 'lodash';
import Moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Form, Icon, Segment } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

// Redux
import { closeLoader, openLoader } from '../../../../../../redux-store/loader';

// Models
import GroupModel, { GroupInterface } from '../../../../../../models/group';

// Components
import { FormSelectOptionsInterface } from '../../../../../components/form/select';
import { ClosedModal, GenericModalProps } from '../../../../../components/genericModal';

// Styled components
import OButton from '../../../../../styled/button';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import './addGroup.scss';


type Props = {
    sequenceId: string;
    isPublishedSequence: boolean;
    onChangeModal: (modal: GenericModalProps) => void,
    onForceUpdate: () => void
    groups: GroupInterface[],
};

const AddGroup = ({ sequenceId, isPublishedSequence = false, onChangeModal, onForceUpdate, groups }: Props) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [groupId, setGroupId] = useState<string>('');
    const [step, setStep] = useState<number>(1);
    const [startOn, setStartOn] = useState<string | undefined>();
    const [endOn, setEndOn] = useState<string | undefined>();

    const [groupToAssignSelectOptions, setGroupToAssignSelectOptions] = useState<FormSelectOptionsInterface[]>([]);

    useEffect(() => {

        const data: FormSelectOptionsInterface[] = [];
        _.each(groups, group => {

            data.push({
                key: group._id,
                text: group.name,
                value: group._id
            });

        });

        setGroupToAssignSelectOptions(data);

    }, [sequenceId, groups]);

    const handleAddGroupSubmit = async () => {

        try {

            closeModal();
            dispatch(openLoader());

            await GroupModel.assignToSequence('' + groupId, sequenceId, startOn, endOn);

            setGroupId('');
            setStartOn(undefined);
            setEndOn(undefined);
            setStep(1);
            onForceUpdate();

        } catch (e) {

            dispatch(closeLoader());
            onChangeModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        }

    }

    const closeModal = () => onChangeModal(ClosedModal);

    return step === 1 ?
        <Form onSubmit={ () => setStep(2) }>
            <Form.Field>
                <span className='stepInfo'>
                    <strong>{ I18n.t('sequences.stepInfo', { step }) }</strong>
                </span>
                <span className='addGroupTitle'>
                    {
                        _.isEmpty(groupToAssignSelectOptions) ?
                            <strong>{ I18n.t('sequences.emptyGroupList') }</strong>
                            :
                            <strong>{ I18n.t('sequences.chooseGroup') }</strong>
                    }
                </span>
                { _.isEmpty(groupToAssignSelectOptions) ?
                    <div style={{ paddingBottom: '10px', display: 'block' }}>
                        <OButton className='textButton' $quarth onClick={ ()=> navigate('/groups/new') } ><span>{ I18n.t('sequences.createGroup')} </span></OButton>
                    </div>
                    :
                    <Dropdown
                        search={ true }
                        options={ groupToAssignSelectOptions }
                        value={ groupId }
                        selection
                        fluid
                        onChange={ (e, { value }) => setGroupId('' + value) }
                        onBlur={ (e, { value }) => setGroupId('' + value) }
                        style={{ marginBottom: '10px' }}
                    />
                }
                <div>
                    <OButton fluid type='submit' $color='white' $terciary disabled={ _.isEmpty(groupId) }>
                        <span>{ I18n.t('buttons.actions.continue') }</span>
                    </OButton>
                </div>
            </Form.Field>
        </Form>
    :  <Form onSubmit={ handleAddGroupSubmit } style={{ height: '500px' }}>
            <Form.Field>
                <span className='stepInfo'>
                    <strong>{ I18n.t('sequences.stepInfo', { step }) }</strong>
                </span>
                <span className='addGroupTitle'>
                    <strong>{ I18n.t('sequences.addPeriod') }</strong>
                </span>
                <label className='primary-font regular'>{ I18n.t('sequences.iniDate') }</label>
                <SemanticDatepicker clearable datePickerOnly format='DD/MM/YYYY' locale='es-ES' onChange={ (e, data) => setStartOn(Moment('' + data.value).format()) } showToday={ false } minDate={ Moment().add(-1, 'days').toDate() } />
                <label className='primary-font regular'>{ I18n.t('sequences.endDate') }</label>
                <SemanticDatepicker clearable datePickerOnly format='DD/MM/YYYY' locale='es-ES' onChange={ (e, data) => setEndOn(Moment('' + data.value).format()) } showToday={ false } minDate={ Moment().add(-1, 'days').toDate() } />
                <div>
                    <OButton fluid type='submit' $color='white' $terciary>
                        <span>{ I18n.t('buttons.actions.continue') }</span>
                    </OButton>
                </div>
                <span style={{ margin: '30px 0', display: 'block', fontSize: '0.75em', fontWeight: 700 }}>{ I18n.t('sequences.periodText1') }</span>
                <span style={{ display: 'block', fontSize: '0.75em', fontWeight: 700 }}>{ I18n.t('sequences.periodText2') }</span>
            </Form.Field>
            {!isPublishedSequence && (
                <Segment className='addStudentWarning'>
                    <Icon name='info circle' size='large' color='yellow'/>
                    <span className='inline'>{I18n.t('sequences.noPublished')}</span>
                </Segment>
            )}
        </Form>
    ;

}

export default AddGroup;