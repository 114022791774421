// Third party libraries
import _ from 'lodash';
import Moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Form, Icon, Segment } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

// Redux
import { closeLoader, openLoader } from '../../../../../../redux-store/loader';

// Models
import StudentModel, { StudentInterface } from '../../../../../../models/student';

// Components
import { FormSelectOptionsInterface } from '../../../../../components/form/select';
import { ClosedModal, GenericModalProps } from '../../../../../components/genericModal';

// Styled components
import OButton from '../../../../../styled/button';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import './addStudent.scss';

type Props = {
    sequenceId: string;
    isPublishedSequence: boolean;
    onChangeModal: (modal: GenericModalProps) => void,
    onForceUpdate: () => void
    students: StudentInterface[],
};

const AddStudent = ({ sequenceId, isPublishedSequence = false, onChangeModal, onForceUpdate, students }: Props) => {

    const dispatch = useDispatch();
    const [studentId, setStudentId] = useState<string>('');
    const [step, setStep] = useState<number>(1);
    const [startOn, setStartOn] = useState<string | undefined>();
    const [endOn, setEndOn] = useState<string | undefined>();

    const [studentToAssignSelectOptions, setStudentToAssignSelectOptions] = useState<FormSelectOptionsInterface[]>([]);

    useEffect(() => {

        const data: FormSelectOptionsInterface[] = [];
        _.each(students, student => {

            data.push({
                key: student._id,
                text: student.name + ' ' + student.surname,
                value: student._id
            });

        });

        setStudentToAssignSelectOptions(data);

    }, [sequenceId, students]);

    const handleAddStudentSubmit = async () => {

        try {

            closeModal();
            dispatch(openLoader());

            await StudentModel.assignToSequence('' + studentId, sequenceId, startOn, endOn);

            setStudentId('');
            setStartOn(undefined);
            setEndOn(undefined);
            setStep(1);
            onForceUpdate();

        } catch (e) {

            dispatch(closeLoader());
            onChangeModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        }

    }

    const closeModal = () => onChangeModal(ClosedModal);

    return step === 1 ? (
      <Form onSubmit={() => setStep(2)}>
        <Form.Field>
          <span className="stepInfo">
            <strong>{I18n.t('sequences.stepInfo', { step })}</strong>
          </span>
          <span className="addStudentTitle">
            <strong>{I18n.t('sequences.chooseStudent')}</strong>
          </span>
          {_.isEmpty(studentToAssignSelectOptions) ? (
            <span
              style={{ paddingBottom: '10px', display: 'block', color: 'red' }}
            >
              <strong>{I18n.t('sequences.emptyStudentList')}</strong>
            </span>
          ) : (
            <Dropdown
              search={true}
              options={studentToAssignSelectOptions}
              value={studentId}
              selection
              fluid
              onChange={(e, { value }) => setStudentId('' + value)}
              onBlur={(e, { value }) => setStudentId('' + value)}
              style={{ marginBottom: '10px' }}
            />
          )}
          <div>
            <OButton
              fluid
              type="submit"
              $color="white"
              $terciary
              disabled={_.isEmpty(studentId)}
            >
              <span>{I18n.t('buttons.actions.continue')}</span>
            </OButton>
          </div>
        </Form.Field>
      </Form>
    ) : (
      <Form onSubmit={handleAddStudentSubmit} style={{ height: '500px' }}>
        <Form.Field>
          <span className="stepInfo">
            <strong>{I18n.t('sequences.stepInfo', { step })}</strong>
          </span>
          <span className="addStudentTitle">
            <strong>{I18n.t('sequences.addPeriod')}</strong>
          </span>
          <label className="primary-font regular">
            {I18n.t('sequences.iniDate')}
          </label>
          <SemanticDatepicker
            clearable
            datePickerOnly
            format="DD/MM/YYYY"
            locale="es-ES"
            onChange={(e, data) => setStartOn(Moment('' + data.value).format())}
            showToday={false}
            minDate={Moment().add(-1, 'days').toDate()}
          />
          <label className="primary-font regular">
            {I18n.t('sequences.endDate')}
          </label>
          <SemanticDatepicker
            clearable
            datePickerOnly
            format="DD/MM/YYYY"
            locale="es-ES"
            onChange={(e, data) => setEndOn(Moment('' + data.value).format())}
            showToday={false}
            minDate={Moment().add(-1, 'days').toDate()}
          />
          <div>
            <OButton fluid type="submit" $color="white" $terciary>
              <span>{I18n.t('buttons.actions.continue')}</span>
            </OButton>
          </div>
          <span
            style={{
              margin: '30px 0',
              display: 'block',
              fontSize: '0.75em',
              fontWeight: 700,
            }}
          >
            {I18n.t('sequences.periodText1')}
          </span>
          <span
            style={{ display: 'block', fontSize: '0.75em', fontWeight: 700 }}
          >
            {I18n.t('sequences.periodText2')}
          </span>
        </Form.Field>
        {!isPublishedSequence && (
          <Segment className='addStudentWarning'>
            <Icon name='info circle' size='large' color='yellow'/>
            <span className='inline'>{I18n.t('sequences.noPublished')}</span>
          </Segment>
        )}
      </Form>
    );

}

export default AddStudent;