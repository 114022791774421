// Third party libraries
import _ from 'lodash';
import { useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useSearchParams } from 'react-router-dom';

// Models
import { NewSequenceInterface, SequenceInterface } from '../../../../models/sequence';

// Styled components
import OButton from '../../../styled/button';

// Locales
import I18n from '../../../../i18n';

// Fill CONST
type Props = {
    baseSequence: NewSequenceInterface | SequenceInterface;
    onSubmit: (newSequence: NewSequenceInterface | SequenceInterface) => void
};

const CloneSequenceModalBody = ({ baseSequence, onSubmit }: Props) => {

    const [name, setName] = useState<string>('');
    
    const [searchParams] = useSearchParams();

    const handleSubmitNewSequence = () => {

        const newSequence: NewSequenceInterface | SequenceInterface = _.clone(baseSequence);
        newSequence.title.ES = name;
        const folderId = searchParams.get('folderId') || 'none';
        newSequence.folderId = folderId;
        onSubmit(newSequence);

    }

    return (
        <Form onSubmit={ handleSubmitNewSequence }>
            <Form.Field>
                <Input
                    fluid
                    onChange={ (e, { value }) => setName(value) }
                    style={{ margin: '15px 0' }}
                />
                <div>
                    <OButton fluid type='submit' $color='white' $terciary disabled={ _.isEmpty(name) } >
                        <span>{ I18n.t('buttons.actions.clone') }</span>
                    </OButton>
                </div>
            </Form.Field>
        </Form>
    );

}

export default CloneSequenceModalBody;