import _ from 'lodash';

type Props = {
    accept?: string;
    input: any;
    onChangeValue?: (newSrc: string) => void;
    resetKey: any;
};

const InputFile = ({ accept, input, onChangeValue = () => {}, resetKey }: Props)  =>  {

    const { value, ...inputProps } = input;

	const handleChange = async (e: { target: { files: any[]; }; }) => {

        if (_.get(e, 'target.files[0]', false)) {

            const reader = new FileReader();

            _.set(window, input.name + '.tempFile', { ref: e.target.files[0] });

            reader.onload = function(event) {

                _.set(window, input.name + '.tempFile.raw', event.target?.result);
                input.onChange('TEMPORAL_UNTIL_PRESS_SAVE');

                onChangeValue(_.get(window, input.name + '.tempFile.raw'));

            };

            const tempFile = _.get(window, input.name + '.tempFile.ref', '');

            const fileBlob = typeof tempFile === 'string' ? new Blob([tempFile]) : tempFile;

            reader.readAsDataURL(fileBlob);

        }

	}

    return <input { ...inputProps } key={ resetKey } type='file' onChange={ handleChange } onBlur={ () => {} } className='hide-input' accept={ accept } />;

};

export default InputFile;