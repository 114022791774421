import { CSSProperties } from 'styled-components';
import I18n from '../../../i18n';

const STYLES = {
  videoIframeContainer: { position: 'relative', width: '100%', height: '100%' },
  videoIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    borderWidth: '0px'
  },
};

type Props = {
  videoUrl: string;
};

export default function VimeoIframe({ videoUrl }: Props) {
  return (
    <div style={STYLES.videoIframeContainer as CSSProperties}>
      <iframe
        src={videoUrl}
        width={640}
        height={360}
        title={I18n.t('sequences.picture')}
        allowFullScreen
        style={STYLES.videoIframe as CSSProperties}
      ></iframe>
    </div>
  );
}
